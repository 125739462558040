import {CSS3DRenderer, CSS3DSprite, CSS3DObject} from 'three-css3d'
/*

    {
        title: 'Titre 2',
        file: 'avion.png',
        imgWidth: "500px",
        isSingle: false,
        posX: 1000,
        posY: 1000,
        links: [
            {
                link: 'https://youtube.com',
                text: 'mon lien 1'
            },
            {
                link: 'https://google.com',
                text: 'mon lien 2'
            }
        ]
    }
*/
const elem = [
    {
        title: 'Airbus | Symposium Skywise 2022',
        file: 'photos/visuel-42-338x450.jpg',
        imgWidth: "338px",
        posX: 0,
        posY: 0,
        isSingle: true,
        link: 'https://www.linkedin.com/posts/airbus-skywise_skywise-airbus-activity-6996526844116107264-ARIb?utm_source=share&utm_medium=member_desktop'
    },
    {
        title: 'Cérémonie Eurostars 2022',
        file: 'photos/visuel-39-578x290.jpg',
        imgWidth: "578px",
        posX: -250,
        posY: 650,
        isSingle: false,
        links: [
            {
                link: 'https://www.linkedin.com/posts/capgemini-invent_automotive-innovation-aneurostars-activity-6988507080970133505-g_V4?utm_source=share&utm_medium=member_desktop',
                text: 'Cérémonie Eurostars 2022'
            },
            {
                link: 'https://www.youtube.com/watch?v=w1bqFX3uBuk',
                text: 'Women@Capgemini a 10 ans'
            }
        ]
    },
    {
        title: 'MALS4Planet : 100% Fresque du Climat',
        file: 'photos/visuel-41-527x296.jpg',
        imgWidth: "527px",
        posX: -870,
        posY: 260,
        isSingle: false,
        links: [
            {
                link: 'https://www.linkedin.com/posts/melaniedaubrosse_mals4planet-sustainability-mals4people-activity-7008088884383211521-ZUVM?utm_source=share&utm_medium=member_desktop',
                text: 'MALS4Planet : 100% Fresque du Climat'
            },
            {
                link: 'https://www.linkedin.com/posts/thierry-jourdain-2b896114_getthefutureyouwant-sustainability-intelligentindustry-activity-7004126552598675456-PMtB?utm_source=share&utm_medium=member_desktop',
                text: 'Certification "Sustainability Campus"'
            }
        ]
    },
    {
        title: `[Blog] MAAP s'ouvre au public`,
        file: 'photos/visuel-43-460x346.jpg',
        imgWidth: "460px",
        posX: -640,
        posY: -240,
        isSingle: true,
        link: 'https://www.capgemini.com/fr-fr/perspectives/blog/plateforme-maap-ouverture-public/'
    },
    {
        title: 'Airbus | RPA Factory, division Helicopters',
        file: 'photos/visuel-34-546x307.jpg',
        imgWidth: "546px",
        posX: 730,
        posY: 730,
        isSingle: false,
        links: [
            {
                link: 'https://prod.ucwe.capgemini.com/fr-fr/actualites/cas-clients/airbus-rpa-factory/',
                text: 'Airbus | RPA Factory, division Helicopters'
            },
            {
                link: 'https://www.linkedin.com/posts/laurent-mazoue-415954236_airbushelicopters-activity-6978277752198053888-9BvM/?utm_source=share&utm_medium=member_desktop',
                text: 'Airbus | Coworking et extended enterprise'
            }
        ]
    },
    {
        title: "[Presse] Renault | La transformation numérique <br>de l'industrie automobile",
        file: 'photos/visuel-35-498x285.jpg',
        imgWidth: "498px",
        posX: 730,
        posY: 150,
        isSingle: true,
        link: 'https://www.lemagit.fr/etude/Renault-motorisera-son-ingenierie-avec-la-plateforme-3DExperience'
    },
    {
        title: `[video] L'industrie intelligente soutient le futur du spatial`,
        file: 'photos/visuel-36-554x292.jpg',
        imgWidth: "498px",
        posX: 550,
        posY: -300,
        isSingle: false,
        links: [
            {
                link: 'https://www.capgemini.com/fr-fr/wp-content/uploads/sites/2/2020/01/Capgemini_SpaceVideo2022.mp4',
                text: `[video] L'industrie intelligente soutient le futur du spatial`
            },
            {
                link: 'https://www.capgemini.com/fr-fr/perspectives/blog/quantifier-impact-pollution-atmospherique/',
                text: `[Blog] Données spatiales et pollution atmosphérique`
            }
        ]
    },
    {
        title: 'SCO | Premier congrès France',
        file: 'photos/visuel-18-655x368.jpg',
        imgWidth: "655px",
        posX: 0,
        posY: -800,
        isSingle: false,
        links: [
            {
                link: 'https://www.linkedin.com/posts/sebastien-lalande-3144831_premier-congr%C3%A8s-du-space-climate-observatory-activity-6876107795842781184-2hSL/?utm_source=share&utm_medium=member_desktop',
                text: 'SCO | Premier congrès France'
            },
            {
                link: 'https://www.spaceclimateobservatory.org/fr/aerolab-space',
                text: 'SCO | Aerolab Space'
            }
        ]
    },
    {
        title: 'Dassault Aviation | Transformation numérique et ERP',
        file: 'photos/visuel-33-464-261.jpg',
        imgWidth: "464px",
        posX: 590,
        posY: 1300,
        isSingle: true,
        link: 'https://www.capgemini.com/fr-fr/actualites/communiques-de-presse/dassault-aviation-transformation-numerique-avec-capgemini/'
    },
    {
        title: 'Sanofi | Accelerate Together',
        file: 'photos/visuel-32-525x331.jpg',
        imgWidth: "525px",
        posX: 1270,
        posY: 1430,
        isSingle: false,
        links: [
            {
                link: 'https://www.linkedin.com/posts/igal-slama-b2915858_capgemini-life-sciences-team-had-a-great-activity-6984272736609042432-x36z/?utm_source=share&utm_medium=member_desktop',
                text: 'Sanofi | Accelerate Together'
            },
            {
                link: 'https://www.linkedin.com/feed/update/urn:li:activity:6922905515496775680/',
                text: 'Sanofi | Innovate Together'
            }
        ]
    },
    {
        title: 'Rugby | Coupe du monde féminine',
        file: 'photos/visuel-30-546x307.jpg',
        imgWidth: "546px",
        posX: 2030,
        posY: 1380,
        isSingle: false,
        links: [
            {
                link: 'https://www.capgemini.com/fr-fr/notre-groupe/passion-sport/capgemini-rugby/women-in-rugby/',
                text: 'Rugby | Coupe du monde féminine'
            },
            {
                link: 'https://www.instagram.com/p/ClT6kjrtBSg/ ',
                text: 'Au contact des villes Coupe du monde 2023'
            }
        ]
    },
    {
        title: 'Salon de l’Auto Paris 2022',
        file: 'photos/visuel-31-512x341.jpg',
        imgWidth: "512px",
        posX: 1540,
        posY: 880,
        isSingle: false,
        links: [
            {
                link: 'https://www.youtube.com/watch?v=GHVb76VyNsU',
                text: `Mondial de l'Auto Paris 2022`
            },
            {
                link: 'https://www.linkedin.com/posts/melaniedaubrosse_psw2022-intelligentindustry-sustainability-activity-6909062963236618240-rEUK?utm_source=linkedin_share&utm_medium=member_desktop_web',
                text: `Paris Space Week`
            }
        ]
    },
    {
        title: 'Hypercar 9X8 | Impact sur les véhicules de masse',
        file: 'photos/visuel-29-504x312.jpg',
        imgWidth: "504px",
        posX: 2390,
        posY: 750,
        isSingle: false,
        links: [
            {
                link: 'https://www.linkedin.com/posts/capgemini_conversation-avec-jean-marc-finot-senior-activity-6949976799351087105-yCbY?utm_source=share&utm_medium=member_desktop',
                text: 'Hypercar 9X8 | <br>Impact sur les véhicules de masse'
            },
            {
                link: 'https://www.linkedin.com/feed/update/urn:li:activity:6952292455639617536?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A6952292455639617536%29',
                text: 'Hypercar 9X8 | <br>Sur la piste de Monza (Italie)'
            },
            {
                link: 'https://www.youtube.com/watch?v=z8S8uldVUGk',
                text: 'Hypercar 9X8 | <br>Témoignage au Mondial de l’Auto'
            },
            {
                link: 'https://www.youtube.com/watch?v=fAoXLdK6bPQ',
                text: 'Hypercar 9X8 | <br>Accélérer son développement'
            },
            {
                link: 'https://www.capgemini.com/fr-fr/notre-groupe/passion-sport/capgemini-peugeot-sport/',
                text: 'Hypercar 9X8 | <br>Union des forces Capgemini et Peugeot Motorsport'
            }
        ]
    },
    {
        title: '[Blog] Existe-t-il <br>une éco-trajectoire réaliste ?',
        file: 'photos/visuel-28-511x327.jpg',
        imgWidth: "511px",
        posX: 1540,
        posY: 320,
        isSingle: false,
        links: [
            {
                link: 'https://www.capgemini.com/fr-fr/perspectives/blog/eco-trajectoire-realiste/',
                text: '[Blog] Existe-t-il <br>une éco-trajectoire réaliste ?'
            },
            {
                link: 'https://www.capgemini.com/fr-fr/perspectives/blog/avion-de-demain-vision-croisee/',
                text: '[Blog] Avion de demain - vision croisée'
            },
            {
                link: 'https://www.capgemini.com/insights/expert-perspectives/burning-hydrogen-in-internal-combustion-engines-a-smart-and-affordable-option-for-reducing-co2-emissions/',
                text: '[Blog] Moteur thermique à hydrogène : <br>solution intelligente à la réduction d’émissions carbone'
            }
        ]
    },
    {
        title: '[CRI] Renault | Intelligent Industry',
        file: 'photos/visuel-27-378x493.jpg',
        imgWidth: "378px",
        posX: 2220,
        posY: 60,
        isSingle: true,
        link: 'https://www.capgemini.com/fr-fr/perspectives/publications/conversations-for-tomorrow-3/discussion-avec-le-groupe-renault/'
    },
    {
        title: 'Movin’On et 5G Open Road font l’Actu',
        file: 'photos/visuel-26-352x527.jpg',
        imgWidth: "352px",
        posX: 1250,
        posY: -385,
        isSingle: true,
        link: 'https://www.linkedin.com/feed/update/urn:li:activity:6898984259474767872/'
    },
    {
        title: 'Industrie Life Sciences : innover, inventer, projeter, appliquer',
        file: 'photos/visuel-25-511x287.jpg',
        imgWidth: "511px",
        posX: 1890,
        posY: -550,
        isSingle: true,
        link: 'https://www.capgemini.com/fr-fr/secteurs/life-sciences/'
    },
    {
        title: 'Digital Continuity Forever<i>!</i>',
        file: 'photos/visuel-24-378x493.jpg',
        imgWidth: "378px",
        posX: 2500,
        posY: -620,
        isSingle: true,
        link: 'https://x-port.capgemini.com/digital-continuity'
    },
    {
        title: 'Michelin | Innovation Day',
        file: 'photos/visuel-22-360x542.jpg',
        imgWidth: "360px",
        posX: 2150,
        posY: -1300,
        isSingle: true,
        link: 'https://www.linkedin.com/feed/update/urn:li:activity:6898984259474767872/'
    },
    {
        title: 'Business Unit MALS : Impact Together Week 2022',
        file: 'photos/visuel-19-511x288.jpg',
        imgWidth: "511px",
        posX: 1710,
        posY: -1860,
        isSingle: true,
        link: 'https://www.linkedin.com/posts/matpeaureaux_lifesciences-impacttogetherweek2022-activity-6983474964309946369-2a64?utm_source=share&utm_medium=member_desktop'
    },
    {
        title: 'Recrutement | <br>Rejoignez le secteur Auto !',
        file: 'photos/visuel-23-512x341.jpg',
        imgWidth: "512px",
        posX: 1500,
        posY: -1060,
        isSingle: false,
        links: [
            {
                link: 'https://www.linkedin.com/posts/arnaud-bouchard-508b62_automobile-talents-capgeminirecrute-activity-6988840299368177664-op1w?utm_source=share&utm_medium=member_desktop',
                text: 'Recrutement | <br>Rejoignez le secteur Auto !'
            },
            {
                link: 'https://www.linkedin.com/posts/elegrossogeti_vivatech-5g-activity-6945627789429493760-EneL?utm_source=share&utm_medium=member_desktop',
                text: 'Recrutement | <br>Soyons fier.e.s de nos projets !'
            },
            {
                link: 'https://www.capgemini.com/fr-fr/carrieres/rencontrez-nos-talents/ricardo/',
                text: 'Mobiliser nos équipes <br>pour atteindre nos objectifs'
            }
        ]
    },
    {
        title: 'AI for Health - Digital Book 2022',
        file: 'photos/visuel-21-408x288.jpg',
        imgWidth: "408px",
        posX: 730,
        posY: -1010,
        isSingle: true,
        link: 'https://www.capgemini.com/fr-fr/perspectives/publications/health-ai-now-and-next/'
    },
    {
        title: 'Références MALS : Booklet H1 2022',
        file: 'photos/visuel-20-546x307.jpg',
        imgWidth: "546px",
        posX: 930,
        posY: -1575,
        isSingle: false,
        links: [
            {
                link: 'https://talent.capgemini.com/media_library/Medias/Outils_de_communication/2022_Booklet_References_H1_FR-BU-MALS.pdf',
                text: 'Références MALS : Booklet H1 2022'
            },
            {
                link: 'https://talent.capgemini.com/media_library/Medias/Outils_de_communication/Booklet_MALS_FY2021.pdf',
                text: 'Références MALS : Booklet 2021'
            }
        ]
    },
    {
        title: 'Newsletter MALS',
        file: 'photos/visuel-17-352x527.jpg',
        imgWidth: "352px",
        posX: 120,
        posY: -1550,
        isSingle: true,
        link: 'https://talent.capgemini.com/fr/pages/notre_organisation/BU/MALS/'
    },
    {
        title: 'Business Unit MALS : Connect with India',
        file: 'photos/visuel-37-518x332.jpg',
        imgWidth: "518px",
        posX: -90,
        posY: 1160,
        isSingle: false,
        links: [
            {
                link: 'https://www.linkedin.com/posts/thierry-jourdain-2b896114_bangalore-india-capgemini-activity-6947986112220950528-_5_y?utm_source=share&utm_medium=member_desktop',
                text: 'Business Unit MALS : Connect with India'
            },
            {
                link: 'https://www.linkedin.com/feed/update/urn:li:activity:6947768475528347648?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A6947768475528347648%29',
                text: 'Team Spirit overseas'
            },
            {
                link: 'https://www.linkedin.com/feed/update/urn:li:activity:7007066582589857792/?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A7007066582589857792%29',
                text: 'Incredible India!'
            }
        ]
    },
    {
        title: 'Airbus | Transformation Cloud',
        file: 'photos/visuel-40-328x477.jpg',
        imgWidth: "328px",
        posX: -1024,
        posY: 810,
        isSingle: false,
        links: [
            {
                link: 'https://www.capgemini.com/news/press-releases/capgemini-has-been-selected-by-airbus-to-implement-a-large-scale-cloud-transformation-program-driving-innovation-and-sustainability/',
                text: 'Airbus | Transformation Cloud <br>à grande échelle x Innovation x développement durable'
            },
            {
                link: 'https://www.capgemini.com/us-en/research-and-insight/capgemini-is-an-engineering-manufacturing-engineering-and-services-strategic-supplier-for-airbus/',
                text: 'Airbus | Fournir les services Engineering <br>et Manufacturing Engineering'
            }
        ]
    },
    {
        title: 'Consortium Airbus / Capgemini | Réseau radio du futur',
        file: 'photos/visuel-38-512x341.jpg',
        imgWidth: "512px",
        posX: -930,
        posY: 1428,
        isSingle: true,
        link: 'https://www.capgemini.com/news/press-releases/consortium-led-by-airbus-and-capgemini-selected-by-the-french-ministry-of-the-interior-for-the-radio-network-of-the-future-rrf/'
    },
    {
        title: 'Evénement Auto, Family and Friends',
        file: 'photos/visuel-13-578x290.jpg',
        imgWidth: "578px",
        posX: -1760,
        posY: 1100,
        isSingle: true,
        link: 'https://www.linkedin.com/posts/arnaud-bouchard-508b62_clients-expertise-activity-6932227185436975105-PdOz?utm_source=share&utm_medium=member_desktop'
    },
    {
        title: 'Airbus | Gain du concours Metaverse',
        file: 'photos/visuel-1-318x477.jpg',
        imgWidth: "318px",
        posX: -2370,
        posY: 1430,
        isSingle: false,
        links: [
            {
                link: 'https://www.airbus.com/en/newsroom/stories/2022-09-exploring-virtual-realitys-future-in-air-travel',
                text: 'Airbus | Gain du concours Metaverse'
            },
            {
                link: 'https://www.capgemini.com/fr-fr/actualites/communiques-de-presse/une-solution-de-realite-virtuelle-pour-diminuer-lanxiete-preoperatoire-des-enfants-chu-montpellier/',
                text: 'Sanofi | Réalité virtuelle, <br>chirurgie pédiatrique, CHU Montpellier'
            }
        ]
    },
    {
        title: 'Schneider Electric | Energy Command Center',
        file: 'photos/visuel-12-518x332.jpg',
        imgWidth: "518px",
        posX: -1600,
        posY: 550,
        isSingle: true,
        link: 'https://www.youtube.com/watch?v=FkHjhWm82N0'
    },
    {
        title: 'BU MALS qui sommes nous',
        file: 'photos/visuel-2-518x332.jpg',
        imgWidth: "518px",
        posX: -2670,
        posY: 844,
        isSingle: true,
        link: 'https://talent.capgemini.com/fr/pages/notre_organisation/BU/MALS/'
    },
    {
        title: 'Schneider Electric | Power Grid',
        file: 'photos/visuel-3-518x332.jpg',
        imgWidth: "518px",
        posX: -2260,
        posY: 374,
        isSingle: true,
        link: 'https://www.capgemini.com/wp-content/uploads/2022/01/Digital-Transformation-for-Grid-Operators.pdf'
    },
    {
        title: '[Video] Capgemini en France',
        file: 'photos/visuel-4-378x493.jpg',
        imgWidth: "378px",
        posX: -2860,
        posY: -40,
        isSingle: true,
        link: 'https://www.youtube.com/watch?v=plqfmXeAc0E'
    },
    {
        title: 'SBM Offshore | Structurer et harmoniser la donnée ',
        file: 'photos/visuel-11-512x341.jpg',
        imgWidth: "512px",
        posX: -2040,
        posY: -220,
        isSingle: false,
        links: [
            {
                link: 'https://vimeo.com/715911686/d441c067f1',
                text: 'SBM Offshore | Structurer et harmoniser la donnée '
            },
            {
                link: 'https://info.ifs.com/construction-navale-et-BTP-webinar.html?utm_campaign=local+campaign+assets&utm_medium=social&utm_source=linkedin.com&utm_content=&utm_term=eci&sc_camp=',
                text: 'Webinar IFS - Capgemini - SBM Offshore'
            }
        ]
    },
    {
        title: '[Podcast] Objectif durable Vinci x St Gobain',
        file: 'photos/visuel-10-334x501.jpg',
        imgWidth: "334px",
        posX: -1400,
        posY: -424,
        isSingle: false,
        links: [
            {
                link: 'https://open.spotify.com/episode/0wE9cA2uocTtv0qykqvtMZ',
                text: '[Podcast] Objectif durable Vinci x St Gobain'
            },
            {
                link: 'https://youtu.be/8fEMZ-Csw_A',
                text: '[video] Superflux : la réponse du circulaire'
            }
        ]
    },
    {
        title: '[Blog] Le cloud, l’avenir de l’Automobile',
        file: 'photos/visuel-5-534x356.jpg',
        imgWidth: "534px",
        posX: -2550,
        posY: -734,
        isSingle: true,
        link: 'https://www.capgemini.com/fr-fr/perspectives/blog/le-cloud-avenir-automobile/'
    },
    {
        title: '[CRI] Intelligent Industry | Jumeau numérique',
        file: 'photos/visuel-6-359x448.jpg',
        imgWidth: "359px",
        posX: -2260,
        posY: -1310,
        isSingle: false,
        links: [
            {
                link: 'https://www.capgemini.com/fr-fr/perspectives/publications/conversations-for-tomorrow-3/perspectives-de-capgemini-jumeaux-numeriques/',
                text: '[CRI] Intelligent Industry | Jumeau numérique'
            },
            {
                link:'https://www.capgemini.com/fr-fr/actualites/communiques-de-presse/capgemini-et-microsoft-collaborent-pour-proposer-une-solution-de-jumeau-numerique-cloud-natif/',
                text: 'Reflect IoD, plateforme de jumeaux numériques'
            }
        ]
    },
    {
        title: 'Alstom | Plateforme de services Cloud',
        file: 'photos/visuel-7-510x288.jpg',
        imgWidth: "510px",
        posX: -2060,
        posY: -1850,
        isSingle: false,
        links: [
            {
                text: 'Alstom | Plateforme de services Cloud',
                link: 'https://www.bfmtv.com/economie/replay-emissions/good-morning-business/aiman-eizzat-capgemini-capgemini-engage-sur-une-dynamique-de-croissance-28-10_VN-202210280139.html'
            },
            {
                link: 'https://capgemini-engineering.com/fr/fr/case_study/alstom/',
                text: 'Alstom | Moderniser le métro français'
            }
        ]
    },
    {
        title: '5G Open Road | La révolution autonome en route',
        file: 'photos/visuel-9-527x296.jpg',
        imgWidth: "527px",
        posX: -1630,
        posY: -978,
        isSingle: false,
        links: [
            {
                link: 'https://www.capgemini.com/fr-fr/actualites/communiques-de-presse/5g-open-road-revolution-autonome-en-route/',
                text: '5G Open Road | La révolution autonome en route'
            },
            {
                link: 'https://vimeo.com/693226267/fc8a425f0a',
                text: '[video] 5G Open Road'
            },
            {
                link: 'https://www.usine-digitale.fr/article/renault-stellantis-et-d-autres-se-penchent-sur-les-benefices-de-la-5g-pour-l-automobile.N2069017',
                text: '[Presse] 5G Open Road | <br>Les bénéfices de la 5G pour l’automobile '
            }
        ]
    },
    {
        title: 'Business Unit MALS : Kick Off 2022',
        file: 'photos/visuel-14-527x307.jpg',
        imgWidth: "527px",
        posX: -870,
        posY: -900,
        isSingle: true,
        link: 'https://capgemini.canalchat.fr/2022/1/'
    },
    {
        title: 'Future4Care | Anniversaire 1 an',
        file: 'photos/visuel-8-512x341.jpg',
        imgWidth: "512px",
        posX: -1460,
        posY: -1490,
        isSingle: false,
        links: [
            {
                link: 'https://www.capgemini.com/fr-fr/actualites/communiques-de-presse/future4care-un-an-au-coeur-de-la-revolution-e-sante/',
                text: 'Future4Care | Anniversaire 1 an'
            },
            {
                link: 'https://www.youtube.com/watch?v=PLlH5nei19Y',
                text: 'Future4Care | Inauguration des locaux'
            }
        ]
    },
    {
        title: 'Business Unit MALS : Higher, Better, Stronger - Together !',
        file: 'photos/visuel-15-534x356.jpg',
        imgWidth: "534px",
        posX: -720,
        posY: -1440,
        isSingle: true,
        link: 'https://www.linkedin.com/posts/thierry-jourdain-2b896114_getthefutureyouwant-mals4ever-wearehiring-activity-6934805910397587456-ZTa8?utm_source=share&utm_medium=member_desktop'
    },
    {
        title: '[Blog] La (vraie) 5G arrive',
        file: 'photos/visuel-16-464x261.jpg',
        imgWidth: "464px",
        posX: -450,
        posY: -1910,
        isSingle: false,
        links: [
            {
                link: 'https://www.capgemini.com/fr-fr/perspectives/blog/la-vraie-5g-arrive-preparez-vous/',
                text: '[Blog] La (vraie) 5G arrive'
            },
            {
                link: 'https://www.capgemini.com/fr-fr/actualites/communiques-de-presse/inauguration-aie-capgemini-a-rennes/',
                text: 'AIE cyber et défense à Rennes'
            }
        ]
    }
    
    
    
]

var objArr = []

elem.forEach(element => {
    let newDiv = document.createElement('div')
    newDiv.classList.add('elem')
    let newImg = document.createElement('img')
    newImg.classList.add('elem__img')
    newImg.src = element.file
    newImg.setAttribute('posX', element.posX)
    newImg.setAttribute('posY', element.posY)
    newImg.setAttribute('hasZoomEvent', true)
    newImg.style.width = element.imgWidth

    if(element.isSingle){
        newDiv.classList.add('elem--multiple')
        let newLinks = document.createElement('div')
        newLinks.classList.add('elem-links')


        let newLink = document.createElement('a')
        newLink.classList.add('elem-links__link','js-cursor-link')
        newLink.href = element.link
        newLink.target = '_blank'
        newLink.innerHTML = element.title
        newLinks.append(newLink)
        
        newDiv.prepend(newLinks)
    }else{
        newDiv.classList.add('elem--multiple')
        let newLinks = document.createElement('div')
        newLinks.classList.add('elem-links')
        element.links.forEach(link => {
            let newLink = document.createElement('a')
            newLink.classList.add('elem-links__link','js-cursor-link')
            newLink.href = link.link
            newLink.target = '_blank'
            newLink.innerHTML = link.text
            newLinks.append(newLink)
        })
        newDiv.prepend(newLinks)
    }

    newDiv.append(newImg)

    var object = new CSS3DObject( newDiv )
    object.position.x = element.posX
    object.position.y = element.posY
    object.position.z = 0

    objArr.push(object)
})

export default objArr