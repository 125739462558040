import './style.scss'
import * as THREE from 'three'
import {CSS3DRenderer, CSS3DSprite, CSS3DObject} from 'three-css3d'
import { gsap, Power1 } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { MapControls } from 'three/examples/jsm/controls/OrbitControls'
import CameraControls from 'camera-controls'
CameraControls.install( { THREE: THREE } )

//Init des variables globales pour ThreeJS
var camera,scene,renderer,cameraControls,clock

//Load elements
import './loadMenu'
import objArr from './loadElements'
import { StereoCamera } from 'three'

//Cursor
gsap.set("#cursor", {xPercent: -50, yPercent: -50});

const cursor = document.querySelector("#cursor");
const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
const mouse = { x: pos.x, y: pos.y };
const speed = .8;

const xSet = gsap.quickSetter(cursor, "x", "px");
const ySet = gsap.quickSetter(cursor, "y", "px");

window.addEventListener("mousemove", e => {    
  mouse.x = e.x;
  mouse.y = e.y;  
});

gsap.ticker.add(() => {
  // adjust speed for higher refresh monitors
  const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio()); 
  
  pos.x += (mouse.x - pos.x) * dt;
  pos.y += (mouse.y - pos.y) * dt;
  xSet(pos.x);
  ySet(pos.y);
});



document.body.onclick = async function(e) { 
    e = e.target;  
    if (e.getAttribute('hasZoomEvent') && !document.getElementById('tuto')){
        let posX = parseInt(e.getAttribute('posX'))
        let posY = parseInt(e.getAttribute('posY'))
        //cameraControls.dampingFactor = .2
        cameraControls.moveTo(posX,posY, 0, true)
        cameraControls.zoomTo(4, true)
        return
    }

    if (
        (e.className && e.className.indexOf('js-close-menu') != -1)
        || (e.className && e.className.indexOf('nav-close') != -1) 
    ){
        resetActiveMenu()
        closeMenu()
        document.getElementById(`nav-ref`).classList.remove('nav--active')
        let elems = document.querySelectorAll('.nav-sub')
        elems.forEach(elem => {
            elem.classList.remove('selected')
        })
    }

    if (e.className && e.className.indexOf('js-toggle-menu') != -1) {
        let elems = document.querySelectorAll('.nav-sub')
        elems.forEach(elem => {
            elem.classList.remove('selected')
        })
        resetActiveMenu()
        let id = e.getAttribute('data-panel')
        e.classList.add('nav-elem--active')
        document.getElementById(`sub-${id}`).classList.add('selected')
        document.getElementById(`nav-ref`).classList.add('nav--active')
    }
}

function resetActiveMenu(){
    let elems = document.querySelectorAll('.nav-elem--active')
    elems.forEach(elem => {
        elem.classList.remove('nav-elem--active')
        console.log(elem)
    })
}

function closeMenu(){
    document.getElementById('nav-menu').classList.remove('active')
    document.getElementById('nav-ref').classList.remove('active')
    let elems = document.querySelectorAll('.nav-sub')
    elems.forEach(elem => {
        elem.classList.remove('selected')
    })
}

//document.getElementById('loader').remove()
let loaderInterval = setInterval(() => {
    //return

    let loader = document.getElementById('loader-progress')
    if(loader.innerText == '100'){
        clearInterval(loaderInterval)
        hideLoader()
        return
    }
    loader.innerText = parseInt(loader.innerText) + 1
}, 14);

document.getElementById('nav-menu').onclick = function(e) {
    e = document.getElementById('nav-menu')   

    if(e.className && e.className.indexOf('active') != -1){
        closeMenu()
        resetActiveMenu()
    }else{
        e.classList.add('active')
        document.getElementById('nav-ref').classList.add('active')
    }
}

function hideLoader(){
    let fadeTarget = document.getElementById('loader')
    var fadeEffect = setInterval(function () {
        if (!fadeTarget.style.opacity) {
            fadeTarget.style.opacity = 1;
        }
        if (fadeTarget.style.opacity > 0) {
            fadeTarget.style.opacity -= 0.1;
        } else {
            fadeTarget.remove()
            clearInterval(fadeEffect);
        }
    }, 40);
}

init();
animate();

function init() {
    clock = new THREE.Clock()
    camera = new THREE.PerspectiveCamera( 35, window.innerWidth / window.innerHeight, 0.1, 100 )
    camera.position.set(0, 0, 7000)
    camera.lookAt(0,0,0)
    scene = new THREE.Scene()
    

    setSection()

    let elems = document.querySelectorAll('.elem__img')
    elems.forEach(elem => {
        console.log(elem)
        elem.onclick = function(e) {
            console.log(e.target)
        }
    })

    renderer = new CSS3DRenderer()
    renderer.setSize( window.innerWidth, window.innerHeight )
    document.getElementById( 'app' ).append( renderer.domElement )


    cameraControls = new CameraControls( camera, renderer.domElement )
    cameraControls.mouseButtons.left = CameraControls.ACTION.TRUCK
    cameraControls.mouseButtons.wheel = CameraControls.ACTION.ZOOM
    cameraControls.touches.one = CameraControls.ACTION.TRUCK
    cameraControls.touches.two = CameraControls.ACTION.TOUCH_ZOOM_TRUCK
    cameraControls.touches.three = CameraControls.ACTION.TRUCK
    cameraControls.maxZoom = 5
    cameraControls.minZoom = 1
    cameraControls.truckSpeed = 3
    cameraControls.draggingDampingFactor = .1
    cameraControls.restThreshold = 1
    cameraControls.addEventListener('controlstart', function(){
        if(document.getElementById('tuto')){
            let fadeTarget = document.getElementById('tuto')
            var fadeEffect = setInterval(function () {
                if (!fadeTarget.style.opacity) {
                    fadeTarget.style.opacity = 1;
                }
                if (fadeTarget.style.opacity > 0) {
                    fadeTarget.style.opacity -= 0.1;
                } else {
                    fadeTarget.remove()
                    clearInterval(fadeEffect);
                }
            }, 20);
        }
    })
    cameraControls.addEventListener('transitionstart', function(){
        document.getElementById('cursor').classList.add('mooving')
        document.getElementById('app').classList.add('in-action')
    })
    cameraControls.addEventListener('rest', function(){
        document.getElementById('cursor').classList.remove('mooving')
        document.getElementById('app').classList.remove('in-action')
        if(cameraControls.camera.zoom > 3){
            document.getElementById('app').classList.add('zoomed')
        }else{
            document.getElementById('app').classList.remove('zoomed')
        }
    })
    cameraControls.addEventListener('update', function(){
        if(cameraControls.camera.zoom > 3){
            document.getElementById('app').classList.add('zoomed')
        }else{
            document.getElementById('app').classList.remove('zoomed')
        }
    })

    window.addEventListener( 'resize', onWindowResize, false )
}

function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize( window.innerWidth, window.innerHeight );
    render()
}

function render() {
    renderer.render( scene, camera );
}
function animate() {
    requestAnimationFrame( animate )
	const delta = clock.getDelta()
	const hasControlsUpdated = cameraControls.update( delta )
    render()
}

function setSection() {
    objArr.forEach(el => {
        scene.add(el)
    })
    return
}