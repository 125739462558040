const menu = [
    {
        titre: 'Aéronautique',
        sub: [
            {
                titre: `Airbus | Transformation Cloud à grande échelle x Innovation x développement durable`,
                link: 'https://www.capgemini.com/news/press-releases/capgemini-has-been-selected-by-airbus-to-implement-a-large-scale-cloud-transformation-program-driving-innovation-and-sustainability'
            },
            {
                titre: `Airbus | Fournir les services Engineering et Manufacturing Engineering`,
                link: 'https://www.capgemini.com/us-en/research-and-insight/capgemini-is-an-engineering-manufacturing-engineering-and-services-strategic-supplier-for-airbus/'
            },
            {
                titre: `Airbus | RPA Factory, division Helicopters`,
                link: 'https://prod.ucwe.capgemini.com/fr-fr/actualites/cas-clients/airbus-rpa-factory/'
            },
            {
                titre: `Airbus | Gain du concours Metaverse`,
                link: 'https://www.airbus.com/en/newsroom/stories/2022-09-exploring-virtual-realitys-future-in-air-travel'
            },
            {
                titre: `Airbus | Coworking et extended enterprise`,
                link: 'https://www.linkedin.com/posts/laurent-mazoue-415954236_airbushelicopters-activity-6978277752198053888-9BvM/?utm_source=share&utm_medium=member_desktop'
            },
            {
                titre: `Airbus | Symposium Skywise 2022`,
                link: 'https://www.linkedin.com/posts/airbus-skywise_skywise-airbus-activity-6996526844116107264-ARIb?utm_source=share&utm_medium=member_desktop'
            }
        ]
    },
    {
        titre: `Défense`,
        sub: [
            {
                titre: `Dassault Aviation | Transformation numérique et ERP`,
                link: 'https://www.capgemini.com/fr-fr/actualites/communiques-de-presse/dassault-aviation-transformation-numerique-avec-capgemini/'
            },
            {
                titre: `AIE cyber et défense à Rennes`,
                link: 'https://www.capgemini.com/fr-fr/actualites/communiques-de-presse/inauguration-aie-capgemini-a-rennes/'
            }
        ]
    },
    {
        titre: `Spatial`,
        sub: [
            {
                titre: `Paris Space Week`,
                link: 'https://www.linkedin.com/posts/melaniedaubrosse_psw2022-intelligentindustry-sustainability-activity-6909062963236618240-rEUK?utm_source=linkedin_share&utm_medium=member_desktop_web'
            },
            { //ok
                titre: `[Blog] MAAP s'ouvre au public`,
                link: 'https://www.capgemini.com/fr-fr/perspectives/blog/plateforme-maap-ouverture-public/'
            },
            { //ok
                titre: `[Blog] Données spatiales et pollution atmosphérique`,
                link: 'https://www.capgemini.com/fr-fr/perspectives/blog/quantifier-impact-pollution-atmospherique/'
            },
            { //ok
                titre: `SCO | Premier congrès France`,
                link: 'https://www.linkedin.com/posts/sebastien-lalande-3144831_premier-congr%C3%A8s-du-space-climate-observatory-activity-6876107795842781184-2hSL/?utm_source=share&utm_medium=member_desktop'
            },
            { //ok
                titre: `SCO | Aerolab Space`,
                link: 'https://www.spaceclimateobservatory.org/fr/aerolab-space'
            }
        ]
    },
    {
        titre: `Auto et Mobilité`,
        sub: [
            { //ok
                titre: `Movin'On et 5G Open Road font l'Actu`,
                link: 'https://www.linkedin.com/feed/update/urn:li:activity:6898984259474767872/'
            },
            { //ok
                titre: `Mondial de l'Auto Paris 2022`,
                link: 'https://www.youtube.com/watch?v=GHVb76VyNsU'
            },
            { //ok
                titre: `Cérémonie Eurostars 2022`,
                link: 'https://www.linkedin.com/posts/capgemini-invent_automotive-innovation-aneurostars-activity-6988507080970133505-g_V4?utm_source=share&utm_medium=member_desktop'
            },
            { //ok
                titre: `[video] 5G Open Road`,
                link: 'https://vimeo.com/693226267/fc8a425f0a'
            },
            { //ok
                titre: `[Blog] Le cloud, l'avenir de l'Automobile`,
                link: 'https://www.capgemini.com/fr-fr/perspectives/blog/le-cloud-avenir-automobile/'
            },
            { //ok
                titre: `[Presse] 5G Open Road | Les bénéfices de la 5G pour l'automobile `,
                link: 'https://www.usine-digitale.fr/article/renault-stellantis-et-d-autres-se-penchent-sur-les-benefices-de-la-5g-pour-l-automobile.N2069017'
            },
            { //ok
                titre: `[Presse] Renault | La transformation numérique de l'industrie automobile`,
                link: 'https://www.lemagit.fr/etude/Renault-motorisera-son-ingenierie-avec-la-plateforme-3DExperience'
            }
        ]
    },
    {
        titre: `Manufacturing`,
        sub: [
            {
                titre: `SBM Offshore | Structurer et harmoniser la donnée `,
                link: 'https://vimeo.com/715911686/d441c067f1'
            },
            {
                titre: `Schneider Electric | Power Grid`,
                link: 'https://www.capgemini.com/wp-content/uploads/2022/01/Digital-Transformation-for-Grid-Operators.pdf'
            },
            {
                titre: `Alstom | Plateforme de services Cloud`,
                link: 'https://www.bfmtv.com/economie/replay-emissions/good-morning-business/aiman-eizzat-capgemini-capgemini-engage-sur-une-dynamique-de-croissance-28-10_VN-202210280139.html'
            },
            {
                titre: `Alstom | Moderniser le métro français`,
                link: 'https://capgemini-engineering.com/fr/fr/case_study/alstom/'
            }
        ]
    },
    {
        titre: `Life Sciences`,
        sub: [
            {
                titre: `Industrie Life Sciences : innover, inventer, projeter, appliquer`,
                link: 'https://www.capgemini.com/fr-fr/secteurs/life-sciences/'
            },
            {
                titre: `Sanofi | Accelerate Together`,
                link: 'https://www.linkedin.com/posts/igal-slama-b2915858_capgemini-life-sciences-team-had-a-great-activity-6984272736609042432-x36z/?utm_source=share&utm_medium=member_desktop'
            },
            {
                titre: `AI for Health - Digital Book 2022`,
                link: 'https://www.capgemini.com/fr-fr/perspectives/publications/health-ai-now-and-next/'
            },
            {
                titre: `Sanofi | Réalité virtuelle, chirurgie pédiatrique, CHU Montpellier`,
                link: 'https://www.capgemini.com/fr-fr/actualites/communiques-de-presse/une-solution-de-realite-virtuelle-pour-diminuer-lanxiete-preoperatoire-des-enfants-chu-montpellier/'
            }
        ]
    },
    {
        titre: `Intelligent Industry`,
        sub: [
            {
                titre: `[CRI] Renault | Intelligent Industry`,
                link: 'https://www.capgemini.com/fr-fr/perspectives/publications/conversations-for-tomorrow-3/discussion-avec-le-groupe-renault/'
            },
            {
                titre: `[Blog] La (vraie) 5G arrive`,
                link: 'https://www.capgemini.com/fr-fr/perspectives/blog/la-vraie-5g-arrive-preparez-vous/'
            },
            {
                titre: `[CRI] Intelligent Industry | Jumeau numérique`,
                link: 'https://www.capgemini.com/fr-fr/perspectives/publications/conversations-for-tomorrow-3/perspectives-de-capgemini-jumeaux-numeriques/'
            },
            {
                titre: `Digital Continuity Forever<i>!</i>`,
                link: 'https://x-port.capgemini.com/digital-continuity'
            }
        ]
    },
    {
        titre: 'MALS4planet',
        sub: [
            {
                titre: `Certification "Sustainability Campus"`,
                link: 'https://www.linkedin.com/posts/thierry-jourdain-2b896114_getthefutureyouwant-sustainability-intelligentindustry-activity-7004126552598675456-PMtB?utm_source=share&utm_medium=member_desktop'
            },
            {
                titre: `Fresque du Climat : 100% collaborateurs BU formés`,
                link: 'https://www.linkedin.com/posts/melaniedaubrosse_mals4planet-sustainability-mals4people-activity-7008088884383211521-ZUVM?utm_source=share&utm_medium=member_desktop'
            },
            {
                titre: `[Blog] Moteur thermique à hydrogène : solution intelligente à la réduction d'émissions carbone`,
                link: 'https://www.capgemini.com/insights/expert-perspectives/burning-hydrogen-in-internal-combustion-engines-a-smart-and-affordable-option-for-reducing-co2-emissions/'
            },
            {
                titre: `[Blog] Existe-t-il une éco-trajectoire réaliste ?`,
                link: 'https://www.capgemini.com/fr-fr/perspectives/blog/eco-trajectoire-realiste/'
            },
            {
                titre: `[video] Superflux : la réponse du circulaire`,
                link: 'https://youtu.be/8fEMZ-Csw_A'
            },{
                titre: `[Podcast] Objectif durable Vinci x St Gobain`,
                link: 'https://open.spotify.com/episode/0wE9cA2uocTtv0qykqvtMZ'
            }
        ]
    },
    {
        titre: `MALS4planet`,
        sub: [
            { //ok
                titre: `Recrutement | Rejoignez le secteur Auto !`,
                link: 'https://www.linkedin.com/posts/arnaud-bouchard-508b62_automobile-talents-capgeminirecrute-activity-6988840299368177664-op1w?utm_source=share&utm_medium=member_desktop'
            },
            { //ok
                titre: `Recrutement | Soyons fier.e.s de nos projets !`,
                link: 'https://www.linkedin.com/posts/elegrossogeti_vivatech-5g-activity-6945627789429493760-EneL?utm_source=share&utm_medium=member_desktop'
            },
            { //ok
                titre: `Mobiliser nos équipes pour atteindre nos objectifs`,
                link: 'https://www.capgemini.com/fr-fr/carrieres/rencontrez-nos-talents/ricardo/'
            },
            { //ok
                titre: `Evénement Auto, Family and Friends`,
                link: 'https://www.linkedin.com/posts/arnaud-bouchard-508b62_clients-expertise-activity-6932227185436975105-PdOz?utm_source=share&utm_medium=member_desktop'
            }
        ]
    },
    {
        titre: `Innovation`,
        sub: [
            {
                titre: `Michelin | Innovation Day`,
                link: 'https://www.linkedin.com/posts/pascalespinouse_edge-oneteam-activity-6938403969777975296-qhuF?utm_source=share&utm_medium=member_desktop'
            },
            {
                titre: `Sanofi | Innovate Together`,
                link: 'https://www.linkedin.com/feed/update/urn:li:activity:6922905515496775680/'
            },
            { //ok
                titre: `[Blog] Avion de demain - vision croisée`,
                link: 'https://www.capgemini.com/fr-fr/perspectives/blog/avion-de-demain-vision-croisee/'
            }
        ]
    },
    {
        titre: `Groupe`,
        sub: [
            {
                titre: `Participation à la Impact Together Week 2022 `,
                link: 'https://www.linkedin.com/posts/matpeaureaux_lifesciences-impacttogetherweek2022-activity-6983474964309946369-2a64?utm_source=share&utm_medium=member_desktop'
            },
            {
                titre: `Consortium Airbus / Capgemini | Réseau radio du futur`,
                link: 'https://www.capgemini.com/fr-fr/actualites/communiques-de-presse/airbus-et-capgemini-reseau-radio-du-futur-rrf/'
            },
            {
                titre: `[Video] Capgemini en France`,
                link: 'https://www.youtube.com/watch?v=plqfmXeAc0E'
            },
            {
                titre: `Women@Capgemini a 10 ans`,
                link: 'https://www.youtube.com/watch?v=w1bqFX3uBuk'
            }
        ]
    },
    {
        titre: `Partenariats`,
        sub: [
            { //ok
                titre: `Future4Care | Anniversaire 1 an`,
                link: 'https://www.capgemini.com/fr-fr/actualites/communiques-de-presse/future4care-un-an-au-coeur-de-la-revolution-e-sante/'
            },
            { //ok
                titre: `Future4Care | Inauguration des locaux`,
                link: 'https://www.youtube.com/watch?v=PLlH5nei19Y'
            },
            { //ok
                titre: `Webinar IFS - Capgemini - SBM Offshore`,
                link: 'https://info.ifs.com/construction-navale-et-BTP-webinar.html?utm_campaign=local+campaign+assets&utm_medium=social&utm_source=linkedin.com&utm_content=&utm_term=eci&sc_camp='
            },
            { //ok
                titre: `Reflect IoD, plateforme de jumeaux numériques`,
                link: 'https://www.instagram.com/p/ClT6kjrtBSg/ '
            },
            { //ok
                titre: `5G Open Road | La révolution autonome en route`,
                link: 'https://www.capgemini.com/fr-fr/actualites/communiques-de-presse/capgemini-et-microsoft-collaborent-pour-proposer-une-solution-de-jumeau-numerique-cloud-natif/'
            }
        ]
    },
    {
        titre: `Hypercar 9X8`,
        sub: [
            { //ok
                titre: `Union des forces Capgemini et Peugeot Motorsport`,
                link: 'https://www.capgemini.com/fr-fr/notre-groupe/passion-sport/capgemini-peugeot-sport/'
            },
            { //ok
                titre: `Accélérer son développement`,
                link: 'https://www.youtube.com/watch?v=fAoXLdK6bPQ'
            },
            { //ok
                titre: `Impact sur les véhicules de masse`,
                link: 'https://www.linkedin.com/posts/capgemini_conversation-avec-jean-marc-finot-senior-activity-6949976799351087105-yCbY?utm_source=share&utm_medium=member_desktop'
            },
            { //ok
                titre: `Sur la piste de Monza (Italie)`,
                link: 'https://www.linkedin.com/feed/update/urn:li:activity:6952292455639617536?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A6952292455639617536%29'
            },
            { //ok
                titre: `Témoignage au Mondial de l'Auto`,
                link: 'https://www.youtube.com/watch?v=z8S8uldVUGk'
            }
        ]
    },
    {
        titre: `BU MALS`,
        sub: [
            { //ok
                titre: `BU MALS qui sommes nous`,
                link: 'https://talent.capgemini.com/fr/pages/notre_organisation/BU/MALS/'
            },
            { //ok
                titre: `Business Unit MALS : Kick Off 2022`,
                link: 'https://capgemini.canalchat.fr/2022/1/'
            },
            { //ok
                titre: `Higher, Better, Stronger - Together !`,
                link: 'https://www.linkedin.com/posts/thierry-jourdain-2b896114_getthefutureyouwant-mals4ever-wearehiring-activity-6934805910397587456-ZTa8?utm_source=share&utm_medium=member_desktop'
            },
            { //ok
                titre: `Newsletter MALS`,
                link: 'https://talent.capgemini.com/fr/pages/notre_organisation/BU/MALS/'
            },
            { //ok
                titre: `Références MALS : Booklet H1 2022`,
                link: 'https://talent.capgemini.com/media_library/Medias/Outils_de_communication/2022_Booklet_References_H1_FR-BU-MALS.pdf'
            },
            { //ok
                titre: `Références MALS : Booklet 2021`,
                link: 'https://talent.capgemini.com/media_library/Medias/Outils_de_communication/Booklet_MALS_FY2021.pdf'
            }
        ]
    },
    {
        titre: `Rugby`,
        sub: [
            {
                titre: `Rugby | Coupe du monde féminine`,
                link: 'https://www.capgemini.com/fr-fr/notre-groupe/passion-sport/capgemini-rugby/women-in-rugby/'
            },
            {
                titre: `Au contact des villes Coupe du monde 2023`,
                link: 'https://www.instagram.com/p/ClT6kjrtBSg/ '
            }
        ]
    },
    {
        titre: `Offshore`,
        sub: [
            {
                titre: `Schneider Electric | Energy Command Center`,
                link: 'https://www.youtube.com/watch?v=FkHjhWm82N0'
            },
            {
                titre: `Business Unit MALS : Connect with India`,
                link: 'https://www.linkedin.com/posts/thierry-jourdain-2b896114_bangalore-india-capgemini-activity-6947986112220950528-_5_y?utm_source=share&utm_medium=member_desktop'
            },
            {
                titre: `Team Spirit overseas`,
                link: 'https://www.linkedin.com/feed/update/urn:li:activity:6947768475528347648?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A6947768475528347648%29'
            },
            {
                titre: `Incredible India!`,
                link: 'https://www.linkedin.com/feed/update/urn:li:activity:7007066582589857792/?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A7007066582589857792%29'
            }
        ]
    }
]

console.log('Loading menu...')

menu.sort((a, b) => a.titre.localeCompare(b.titre)).forEach((element, index) => {
    let menuTitle = document.createElement('div')
    menuTitle.classList.add('nav-elem')
    let menuTitleText = document.createElement('p')
    menuTitleText.classList.add('nav-elem__titre','js-toggle-menu')
    menuTitleText.innerHTML = element.titre
    menuTitleText.setAttribute('data-panel', index)
    menuTitle.append(menuTitleText)
    document.getElementById('main-panel-container').append(menuTitle)

    let subMenu = document.createElement('div')
    subMenu.classList.add('nav-sub')
    subMenu.setAttribute('id', `sub-${index}`)

    element.sub.forEach(subMenuElem => {
        let subTitle = document.createElement('a')
        subTitle.classList.add('nav-sub__titre')
        subTitle.innerHTML = subMenuElem.titre
        subTitle.target = '_blank'
        subTitle.href = subMenuElem.link
        subMenu.append(subTitle)
    })

    document.getElementById('alt-panel').append(subMenu)
})